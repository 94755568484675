import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "What's the deal with Genshin Impact Wishes? (Part 1)",
  "details": "A far too comprehensive guide to the pity system",
  "date": "2021-06-06",
  "tags": ["Statistics", "Genshin Impact", "Gaming"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Let's talk Genshin, Gacha, and Statistics!`}</h2>
    <p>{`Genshin Impact is a free-to-play game developed by miHoYo in which players to use up to four characters to explore an open world and participate in its many activities. The primary method of acquiring new valuable characters and weapons is through "wishing". Wishing is a method in which you spend in-game currency to randomly acquire new items. This is similar to opening packs of cards, and is known as a `}<a href="https://en.wikipedia.org/wiki/Gacha_game" target="_blank">{`gacha system`}</a>{`. Like most gacha games, there are varying chances for acquiring items of different rarities. In Genshin, there are three rarities that you may summon for: 3-Star, 4-Star, and 5-Star; with 5-star being the most rare. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.78723404255319%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAADE0lEQVQozxXR/U+UBQDA8edf6A/IHyjuHe7tebhXnnvu7TnvOOLAu3vuAEHeRM8QZ6cTw0ErxFSsHJjL2mVeQcvFKhFsCz22zLXGYJYWUj80Z1brbWNU1Py2Pv/CR0htb2ChNEm3lqcpHON25QarlQoDrVmOaD6unc+w+I7G3Jsp+nfJjA+qfHu1kwenYtxybuNFrwWjwYZLChCNNSFU62wcLx4kG49zt3IdPpmD9TXur6ww0KRwOK0Qd4qkgk4s1SZUk56MIjJXyvGg6GdR1nF5b5jdkg1djRfhSYNIuD7M9MsnYWOT3z+c4d+vbvPT2j3yYZlWNUpbJIRSI7ErEebCYBct7gBmm5OHN/ax1uNm67UUVxIO9AYRwWhx45K8jB4Y4O+HP/DPxl+wucX91c8Zyzcw3pGmvzGC5nFyLNPESGuO0VYVyerk49NtvDsYZblQx0iwDl2NB0FvdqGqcbyiyGdXr8DGBlu//MbCuTEu9WsMhLx0BX0MZ1QKT4Wod0gMtjcz1pPlQj7CUINMKSqStIuEgwqCqaaeHeEQ+bif/4N+vXcHHsHliTFypsfYmwmS8jsY3d9F745GmhUPEZ+fVlVmuqgykXAzVGvmhbyfg4XtCG5rHV8cSzI7dYCjT7fQnclxd+k6j75f5+yhPgKiyFChl5/Xl/ny03kk3TYsVVXkYiqTR6Pk7BaKkp3yiUbm39YQnjBInOuM8MozSbQGH331ErLLTemlMyyV3+LWzEWulaZY+eANDhX6MBltRKxVaCEvvWYTU5qHfFShvTNK+fUsgsnqJiX7+OhEH26HxHTWxWQxjeRRyKaaOT0yjN3mIx0N0R4LEQ+HaIvY2e0X6XDYGE4HyCYDLN/sZv+eGIKxxoWjykTl+R5WZkY4LDtZfTbBqb4IqWQzBr1IXHYh+7zs61B5bo/CezsVTnpsaHoj/V6Ji+dzjPfIGPVOBJ3Zhd9iY7i2mj/LRTaXzvDH+wU2vxnn5sIRZqeLrM+3cafczo+LY6xOaHx9PMGrO/3Mtlj57myMS7l6ah+3UG128R/GrO97fR0e3gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "5 Star Hu Tao",
          "title": "5 Star Hu Tao",
          "src": "/static/43b9f1f5f847f0b3a44796f5ab97b795/1d69c/my-Hu-Tao-summon-pog.png",
          "srcSet": ["/static/43b9f1f5f847f0b3a44796f5ab97b795/4dcb9/my-Hu-Tao-summon-pog.png 188w", "/static/43b9f1f5f847f0b3a44796f5ab97b795/5ff7e/my-Hu-Tao-summon-pog.png 375w", "/static/43b9f1f5f847f0b3a44796f5ab97b795/1d69c/my-Hu-Tao-summon-pog.png 750w", "/static/43b9f1f5f847f0b3a44796f5ab97b795/78797/my-Hu-Tao-summon-pog.png 1125w", "/static/43b9f1f5f847f0b3a44796f5ab97b795/aa440/my-Hu-Tao-summon-pog.png 1500w", "/static/43b9f1f5f847f0b3a44796f5ab97b795/ec09f/my-Hu-Tao-summon-pog.png 1916w"],
          "sizes": "(max-width: 750px) 100vw, 750px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`MiHoYo discloses the rates for acquiring an item of each rarity, but there's just one problem - there's an internal contradiction in the rates they suggest. Furthermore, the disclosed rates seem to defy most players' wishing experiences, which leads these gamers to resort to the dark arts of statistics. And that is what we will be doing here today.`}</p>
    <h2>{`So where's the contradiction?`}</h2>
    <p>{`The details for the rates can be found here on the `}<a href="https://genshin-impact.fandom.com/wiki/Wishes" target="_blank">{`wiki`}</a>{`. Here's the idea:`}</p>
    <p>{`Whenever you wish, you have a chance of getting a 3-Star, 4-Star, or 5-Star.`}</p>
    <ol>
      <li parentName="ol">{`The chance for a 5-Star is 0.6%.`}</li>
      <li parentName="ol">{`The chance for a 4-Star is 5.1%.`}</li>
      <li parentName="ol">{`The chance for a 3-Star is 94.3%.`}</li>
    </ol>
    <p>{`With just the above rules alone, repeated summons simply follow a binomial distribution. However, there is an additional 'pity' system which makes the summons no longer independent. In particular:`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`If you summon 9 times and do not recieve a 4-Star, you will recieve one on the 10th summon.`}</li>
      <li parentName="ol">{`If you summon 89 times and do not recieve a 5-Star, you will recieve one on the 90th summon.`}</li>
      <li parentName="ol">{`The count for the above conditions reset to 0 whenever you summon the respective rarity.`}</li>
    </ol>
    <p>{`Because the wishes are no longer independent, the chance for the higher rarities become more obscure. A 'consolidated rate', or average rate including pity, seeks to clarify how many of each rarity you would expect with many summons. MiHoYo claims `}</p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`There is a 1.6% 5-Star consolidated rate (around 1.6% of your summons can be expected to be 5 stars)`}</li>
      <li parentName="ol">{`There is a 13% consolidated rate for 4-Stars`}</li>
    </ol>
    <p>{`But all these rules, taken together, cannot hold! They are invalid statistically speaking, because rules 1 through 6 contradict the values for rules of 7 and 8.`}</p>
    <p>{`There are two ways of discussing this contradiction:`}</p>
    <ol>
      <li parentName="ol">{`Analytically`}</li>
      <li parentName="ol">{`Emperically, through simulations`}</li>
    </ol>
    <p>{`Let's dive in!`}</p>
    <h2>{`The Analytical Contradiction`}</h2>
    <p>{`The goal is to use rules 1-6 in order to calculate the consolidated rate. First, however, I'd like to present two much simpler questions, to provide insight into how we reach our calculation (maybe skip these if you're already a pro).`}</p>
    <h3>{`Starting Simple`}</h3>
    <p>{`Consider if we roll a fair six sided dice. Each side has a 1/6 chance, and is labelled 1 through 6. What is the average number we would expect? Indeed, we may calculate the average of these numbers to find that this value is 3.5. 3.5 in this way represents the expected average of all the 'mapped' values (1-6) when rolling the dice many times. However, it's not always the case that the probabilities are equal, so what do we do then? The Expected value is the following:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`E`}</mi><mi parentName="mrow">{`X`}</mi><mo parentName="mrow">{`=`}</mo><msub parentName="mrow"><mi parentName="msub">{`μ`}</mi><mi parentName="msub">{`x`}</mi></msub><mo parentName="mrow">{`=`}</mo><munderover parentName="mrow"><mo parentName="munderover">{`∑`}</mo><mrow parentName="munderover"><mi parentName="mrow">{`i`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`1`}</mn></mrow><mi parentName="munderover">{`n`}</mi></munderover><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`∗`}</mo><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`EX = \\mu_x = \\sum_{i=1}^{n} P(x_i)*x_i`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.68333em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07847em"
                }
              }}>{`EX`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.19444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`μ`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.151392em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`x`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.929066em",
                  "verticalAlign": "-1.277669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop op-limits"
              }}><span parentName="span" {...{
                  "className": "vlist-t vlist-t2"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.6513970000000002em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-1.872331em",
                          "marginLeft": "0em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "sizing reset-size6 size3 mtight"
                        }}><span parentName="span" {...{
                            "className": "mord mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span><span parentName="span" {...{
                              "className": "mrel mtight"
                            }}>{`=`}</span><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3.050005em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span"><span parentName="span" {...{
                            "className": "mop op-symbol large-op"
                          }}>{`∑`}</span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-4.3000050000000005em",
                          "marginLeft": "0em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "sizing reset-size6 size3 mtight"
                        }}><span parentName="span" {...{
                            "className": "mord mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`n`}</span></span></span></span></span><span parentName="span" {...{
                      "className": "vlist-s"
                    }}>{`​`}</span></span><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.277669em"
                      }
                    }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.31166399999999994em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`∗`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.58056em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.31166399999999994em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`for our case above, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(x_i)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.31166399999999994em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{` doesn't change, and remains at 1/6. `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x_i`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.58056em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.31166399999999994em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` would represent our mapping of 1 through 6. A table might look something like this:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`xi`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`P(xi)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`P(xi)*xi`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1*(1/6)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2*(1/6)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3*(1/6)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4*(1/6)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5*(1/6)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6*(1/6)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Summing that third column produces the expected value, and knowing how to do this will be helpful later on.`}</p>
    <p>{`Another idea to keep in mind is how to deal with subsequent events. This is handled through multiplying each likelihood, and is often visualized with probability trees. This is fairly well known, and is introduced with things like the chance of getting three heads in a row, flipping three times: `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`0.5`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`0.5`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`0.5`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`0.5`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo><mn parentName="msup">{`3`}</mn></msup><mo parentName="mrow">{`=`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`0.125`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`(0.5)(0.5)(0.5) = (0.5)^3 = (0.125)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.5`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.5`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.5`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.064108em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.5`}</span><span parentName="span" {...{
                "className": "mclose"
              }}><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8141079999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`3`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.125`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{`.`}</p>
    <h3>{`Finally The Consolidated Rate, Maybe`}</h3>
    <p>{`The chance you get a 5 star on exactly your first pull is (0.006). Neat. The chance you get a 5 star on your second pull is (0.006)(1-0.006), because you failed the first time. The third pull is of course (0.006)(1-0.006)(1-0.006). We can generalize this pattern for n pulls:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`0.006`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`0.006`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo><mrow parentName="msup"><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msup></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`(0.006)(1-0.006)^{n-1}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.006`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1141079999999999em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.006`}</span><span parentName="span" {...{
                "className": "mclose"
              }}><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.864108em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`n`}</span><span parentName="span" {...{
                                "className": "mbin mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`for n 1...89. For 90 pulls, you 'missed' 90 times, and so, the chance you REACH 90 pulls (NOT the chance you 'succeed' at 90, which is 100% from pity) is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`0.006`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo><mn parentName="msup">{`89`}</mn></msup></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`(1-0.006)^{89}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.064108em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.006`}</span><span parentName="span" {...{
                "className": "mclose"
              }}><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8141079999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`89`}</span></span></span></span></span></span></span></span></span></span></span></span></span></p>
    <p>{`So now we have `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x_i`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.58056em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.31166399999999994em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`=n, and a `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(x_i)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.31166399999999994em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{`, and we may calculate our expected value to determine our expected value to acquire a single summon.`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`μ`}</mi><mi parentName="msub">{`x`}</mi></msub><mo parentName="mrow">{`=`}</mo><munderover parentName="mrow"><mo parentName="munderover">{`∑`}</mo><mrow parentName="munderover"><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`1`}</mn></mrow><mn parentName="munderover">{`89`}</mn></munderover><mi parentName="mrow">{`n`}</mi><mn parentName="mrow">{`0.006`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow">{`−`}</mo><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`.`}</mi><mn parentName="mrow">{`006`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo><mrow parentName="msup"><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msup><mo parentName="mrow">{`+`}</mo><mn parentName="mrow">{`90`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow">{`−`}</mo><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`.`}</mi><mn parentName="mrow">{`006`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo><mn parentName="msup">{`89`}</mn></msup><mo parentName="mrow">{`≈`}</mo><mn parentName="mrow">{`69.69`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mu_x = \\sum_{n=1}^{89} n 0.006(1-.006)^{n-1} + 90(1-.006)^{89} \\approx 69.69`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.19444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`μ`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.151392em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`x`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.068226em",
                  "verticalAlign": "-1.267113em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop op-limits"
              }}><span parentName="span" {...{
                  "className": "vlist-t vlist-t2"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.8011130000000004em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-1.882887em",
                          "marginLeft": "0em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "sizing reset-size6 size3 mtight"
                        }}><span parentName="span" {...{
                            "className": "mord mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`n`}</span><span parentName="span" {...{
                              "className": "mrel mtight"
                            }}>{`=`}</span><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3.050005em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span"><span parentName="span" {...{
                            "className": "mop op-symbol large-op"
                          }}>{`∑`}</span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-4.3000050000000005em",
                          "marginLeft": "0em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "sizing reset-size6 size3 mtight"
                        }}><span parentName="span" {...{
                            "className": "mord mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`89`}</span></span></span></span></span><span parentName="span" {...{
                      "className": "vlist-s"
                    }}>{`​`}</span></span><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.267113em"
                      }
                    }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.006`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1141079999999999em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`.006`}</span><span parentName="span" {...{
                "className": "mclose"
              }}><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.864108em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`n`}</span><span parentName="span" {...{
                                "className": "mbin mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`+`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`90`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1141079999999999em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`.006`}</span><span parentName="span" {...{
                "className": "mclose"
              }}><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641079999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`89`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.64444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`69.69`}</span></span></span></span></span></div>
    <p>{`A quick table, to again visualize this:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`xi`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`P(xi)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`xi*P(xi)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`0.006`}</mn></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`0.006`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.64444em",
                        "verticalAlign": "0em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`0.006`}</span></span></span></span></span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1*(0.006)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`0.006`}</mn><mo parentName="mrow" {...{
                          "stretchy": "false"
                        }}>{`(`}</mo><mi parentName="mrow" {...{
                          "mathvariant": "normal"
                        }}>{`.`}</mi><mn parentName="mrow">{`994`}</mn><mo parentName="mrow" {...{
                          "stretchy": "false"
                        }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`0.006(.994)`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1em",
                        "verticalAlign": "-0.25em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`0.006`}</span><span parentName="span" {...{
                      "className": "mopen"
                    }}>{`(`}</span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`.994`}</span><span parentName="span" {...{
                      "className": "mclose"
                    }}>{`)`}</span></span></span></span></span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2*0.006(.994)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`0.006`}</mn><mo parentName="mrow" {...{
                          "stretchy": "false"
                        }}>{`(`}</mo><mi parentName="mrow" {...{
                          "mathvariant": "normal"
                        }}>{`.`}</mi><mn parentName="mrow">{`994`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                            "stretchy": "false"
                          }}>{`)`}</mo><mn parentName="msup">{`2`}</mn></msup></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`0.006(.994)^2`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1.064108em",
                        "verticalAlign": "-0.25em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`0.006`}</span><span parentName="span" {...{
                      "className": "mopen"
                    }}>{`(`}</span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`.994`}</span><span parentName="span" {...{
                      "className": "mclose"
                    }}><span parentName="span" {...{
                        "className": "mclose"
                      }}>{`)`}</span><span parentName="span" {...{
                        "className": "msupsub"
                      }}><span parentName="span" {...{
                          "className": "vlist-t"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.8141079999999999em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-3.063em",
                                  "marginRight": "0.05em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "2.7em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span></span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3*...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`0.006`}</mn><mo parentName="mrow" {...{
                          "stretchy": "false"
                        }}>{`(`}</mo><mi parentName="mrow" {...{
                          "mathvariant": "normal"
                        }}>{`.`}</mi><mn parentName="mrow">{`994`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                            "stretchy": "false"
                          }}>{`)`}</mo><mn parentName="msup">{`3`}</mn></msup></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`0.006(.994)^3`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1.064108em",
                        "verticalAlign": "-0.25em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`0.006`}</span><span parentName="span" {...{
                      "className": "mopen"
                    }}>{`(`}</span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`.994`}</span><span parentName="span" {...{
                      "className": "mclose"
                    }}><span parentName="span" {...{
                        "className": "mclose"
                      }}>{`)`}</span><span parentName="span" {...{
                        "className": "msupsub"
                      }}><span parentName="span" {...{
                          "className": "vlist-t"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.8141079999999999em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-3.063em",
                                  "marginRight": "0.05em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "2.7em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}>{`3`}</span></span></span></span></span></span></span></span></span></span></span></span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4*...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`89`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`0.006`}</mn><mo parentName="mrow" {...{
                          "stretchy": "false"
                        }}>{`(`}</mo><mi parentName="mrow" {...{
                          "mathvariant": "normal"
                        }}>{`.`}</mi><mn parentName="mrow">{`994`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                            "stretchy": "false"
                          }}>{`)`}</mo><mn parentName="msup">{`88`}</mn></msup></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`0.006(.994)^{88}`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1.064108em",
                        "verticalAlign": "-0.25em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`0.006`}</span><span parentName="span" {...{
                      "className": "mopen"
                    }}>{`(`}</span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`.994`}</span><span parentName="span" {...{
                      "className": "mclose"
                    }}><span parentName="span" {...{
                        "className": "mclose"
                      }}>{`)`}</span><span parentName="span" {...{
                        "className": "msupsub"
                      }}><span parentName="span" {...{
                          "className": "vlist-t"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.8141079999999999em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-3.063em",
                                  "marginRight": "0.05em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "2.7em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mtight"
                                    }}>{`88`}</span></span></span></span></span></span></span></span></span></span></span></span></span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`89*...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`90`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                          "stretchy": "false"
                        }}>{`(`}</mo><mi parentName="mrow" {...{
                          "mathvariant": "normal"
                        }}>{`.`}</mi><mn parentName="mrow">{`994`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                            "stretchy": "false"
                          }}>{`)`}</mo><mn parentName="msup">{`89`}</mn></msup></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`(.994)^{89}`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1.064108em",
                        "verticalAlign": "-0.25em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mopen"
                    }}>{`(`}</span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`.994`}</span><span parentName="span" {...{
                      "className": "mclose"
                    }}><span parentName="span" {...{
                        "className": "mclose"
                      }}>{`)`}</span><span parentName="span" {...{
                        "className": "msupsub"
                      }}><span parentName="span" {...{
                          "className": "vlist-t"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.8141079999999999em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-3.063em",
                                  "marginRight": "0.05em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "2.7em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mtight"
                                    }}>{`89`}</span></span></span></span></span></span></span></span></span></span></span></span></span></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90*...`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The expected value is nicely ~69.69 wishes for a single five star unit, if we keep repeating this summon until a 5 star within 90 game. In this manner, we may expect a consolidated rate of approximately 1/69.69 = 0.01435, or 1.435%. This is certainly different from 1.6%! `}</p>
    <h2>{`The Emperical Contradiction`}</h2>
    <p>{`Even though we've calculated the consolidated rate, and discovered it was not 1.6%, but rather 1.435%, let's reproduce this with simulations (in python).`}</p>
    <p>{`First, my imports:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` numpy `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` np
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` pandas `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` pd
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` random
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` collections `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` Counter
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` scipy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`stats`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Next, I define a simple roll function:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`roll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`probMassFunc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.006`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.051`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.943`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` numRolls `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token triple-quoted-string string"
          }}>{`"""
  Basic roll function. Samples a roll using numPy Cythonized random choice.
  """`}</span>{`
  value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`choice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`numRolls`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`replace`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`p`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`probMassFunc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` value`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`If we try this roll function, we will get a value of 0 1 or 2, using their respecitve weights as given by the pmf.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-text line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`outcome = roll()
outcome[0]`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`However, this sort of role doesn't implement pity. The next block of code is a lazy implementation which keeps track of your 4 and 5 stars with the global variable count4 and count5. Of course, when either reach the pity, the roll will accomidate.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#A count of how many rolls since the last 4/5 star`}</span>{`
count4 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
count5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`conditionalRoll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token triple-quoted-string string"
          }}>{`"""
  INPUT: None
  OUTPUT: returns 0,1,2
  FUNCTIONALITY: A roll function checking conditionals. 4* every 10, and 5* every 90.
  """`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`global`}</span>{` count4
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`global`}</span>{` count5

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Performing the conditional roll. If 5 star guaruntee, then 4 star, then regular roll.`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`count5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`89`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    outcome `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`elif`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`count5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`90`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`and`}</span>{` count4 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    outcome `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` roll`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`probMassFunc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.006`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.994`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    outcome `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` roll`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Extracts value from np.array to int`}</span>{`
  outcomeValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` outcome`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Checks if a 4 star was rolled and adjusts counter`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`outcomeValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    count5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` count5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    count5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Checks if a 4 star was rolled and adjusts counter`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`outcomeValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    count4 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` count4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    count4 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` outcomeValue`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Using the implementation is pretty straight forward:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#A count of how many rolls since the last 4/5 star`}</span>{`
count4 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
count5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`rollValue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` conditionalRoll`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
rollValue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`count4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`count5

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#(2, 1, 1)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#(2, 2, 2)...so on`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Now that we have the rolls functional, we simply need to 'bootstrap' this, or simply carry it out many times.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#Now we boothstrap the conditionalRoll, and measure the results`}</span>{`

attemptSize `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`49950`}</span>{`
resultArray `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`zeros`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`attemptSize`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` resultIndex `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`range`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`attemptSize`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#performing a roll and storing into a very large array`}</span>{`
  resultArray`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`resultIndex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` conditionalRoll`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`The result of which may vary a little due to the random 'stochastic' nature of the simulations.`}</p>
    <p>{`Now, let's derive our consolidated rate from our many rolls. The consolidated rate is simply the number of a particular star divided by the number of rolls. We use a counter to count how many of each star quality appeared in our simulation.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`countDict `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Counter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`resultArray`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
countDict
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Counter({0.0: 709, 1.0: 6073, 2.0: 43168})`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`The counter acts as a dictionary of keys (0,1,2) associated with values (709, 6073, 43168). Next we simply divide by the total summons.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`consolidated5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` countDict`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`attemptSize
consolidated4 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` countDict`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`attemptSize
consolidated5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`consolidated4
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#(0.014194194194194194, 0.12158158158158158)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`The consolidated rate for this simulation was about 1.42%, which is closer to 1.435% than 1.6%, but we can get this to be a little more accurate. One way is to carry out the single simulation further out. Another, which we will proceed to do, is to carry out several simulation sets, to produce a distribution!`}</p>
    <p>{`To do this, we encapsulate the process we just did into a loop, and carry it out n times (I chose 10). This is `}<em parentName="p">{`much`}</em>{` more cleanly done with a few lines of code with a single function encapsulating the above. However, I wrote out the implementation in this way so each step is very intuitive.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`bootstrapDistribution`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
`}<span parentName="code" {...{
            "className": "token triple-quoted-string string"
          }}>{`"""
INPUT: none
OUTPUT: consolidated rate for 4* and 5* as array
Functionality: Runs a bootstrap of simulation 10 times
"""`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Empty array to store consolidated results`}</span>{`
  numBootStrap `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{`
  consolidated5Distribution `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`zeros`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`numBootStrap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  consolidated4Distribution `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`zeros`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`numBootStrap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Defining the array of rolls and its size`}</span>{`
  attemptSize `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10000`}</span>{` 
  resultArray `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`zeros`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`attemptSize`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Bootstrapping many rolls`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` consolidatedIndex `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`range`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Rolling attemptSize number of times`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` resultIndex `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`range`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`attemptSize`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      resultArray`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`resultIndex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` conditionalRoll`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Creating a dict counter of the array`}</span>{`
    countDict `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Counter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`resultArray`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Calculating the consolidated rate for 5 and 4 star`}</span>{`
    consolidated5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` countDict`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`attemptSize
    consolidated4 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` countDict`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`attemptSize

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`consolidated5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    consolidated5Distribution`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`consolidatedIndex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` consolidated5
    consolidated4Distribution`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`consolidatedIndex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` consolidated4

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` consolidated5Distribution`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`consolidated4Distribution`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`This will return the consolidated rate for each of the 10 bootstraps.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`consolidated5Distribution`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`consolidated4Distribution `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` bootstrapDistribution`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span></span></pre></div>
    <p>{`We can use pandas to neatly store these values into a dataframe.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`df `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` pd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`DataFrame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'fiveStarConsolidated'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`consolidated5Distribution`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fourStarConsolidated'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`consolidated4Distribution`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
df`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Using pandas `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`df.describe()`}</code>{` will allow us to generate some simple descriptive statistics about the distribution of consolidated rates, which gives us a better reflection of what it may be. I happened to get results as follows.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`fiveStarConsolidated`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`fourStarConsolidated`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`mean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.014340`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.122920`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`std`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.000869`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.003588`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`min`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.013200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.117200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`25%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.013600`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.121050`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`50%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.014200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.123200`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`75%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.015000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.125050`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`max`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.015800`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.128400`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`This time, we acquired a consolidated rate of 1.43% using the mean of this distribution, which is fairly close. We also get an idea of how tightly is hugs the mean, along with any other descriptive statistics. However, our four star rate was a bit off from 13%. As we take more samples, the distribution of the sample means will become more normally distributed, and its mean should converge to our calculated consolidated rate.`}</p>
    <p>{`If we wanted to be mathematically rigorous, we could prove that the 1.43% is very different from the 1.6% by taking a t-test using our sampled simulation data.`}</p>
    <h2>{`Comparison of Consolidated Rates`}</h2>
    <p>{`We used two different methods to calculate the consolidated rate, both of which roughly approximating to 1.43% for the consolidated rate assuming a flat 0.6% on each pull. Of course, this directly contradicts 1.6%. So, which is it? `}</p>
    <p>{`In `}<a parentName="p" {...{
        "href": "/blog/genshin-wishes-p2"
      }}>{`part two`}</a>{`, we will discuss alternative summoning methods which conserve the 1.6% rule by sacrificing the flat 0.6% chance assumption, and adheres more closely to player experiences.`}</p>
    <p>{`See you then!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      